
import { defineComponent, toRefs, ref, computed, watch } from "vue";
import { Form, } from "vee-validate";
import InputText from "@/components/forms/InputText.vue" 
import SelectFilterRemote from "@/components/forms/SelectFilterRemote.vue" 
import { FormTypes } from "@/core/enums/form-types"
import * as Validations from "yup";
import moduleForm from '@/store/modules/population/modules/new-population'
import NewPopulation from "@/models/population/NewPopulation";

export default defineComponent({
    components:{
        Form
        , InputText
        , SelectFilterRemote
    }
    , props:{
        formType: {//indica como se comportara el formulario
            type: Number
            , default: FormTypes.Create
        }
    },
    emits: ['close-content']
    , setup(props, { emit }){
        const disabledState = ref(true);
        const countryId = ref('');
        const { formType } = toRefs(props);
        const form = ref();
        const validationSchema =  Validations.object().shape({
            name: Validations.string().default('').required()
            , countryId: Validations.string().default('').required()
            , stateId: Validations.string().default('').required()
        });
        
        const save = (form: NewPopulation, { resetForm }) => {
             //call action
            if(formType.value == FormTypes.Create){
                moduleForm.SAVE_FORM(form).then( (isSuccess) => {
                    if(isSuccess){
                        emit("close-content")
                        resetForm();
                    }
                });
            } else {//edita si no es create
                moduleForm.UPDATE_FORM(form).then(  (isSuccess) => {
                    if(isSuccess){
                        emit("close-content")
                        resetForm();
                    }
                });
            }
        }
        const searchCountry = (filtername: string) => moduleForm.getComboCountry(filtername);
        const searchState = (filtername: string) => { 
            moduleForm.getComboState({ filterName: filtername, countryId: countryId.value } );
        }
        const countryChange = (value: string) => {
            //si cambia el valor indicar que ya se puede activar el otro control
            disabledState.value = false;
            countryId.value = value;
        }
        const clearComboState = () => {
            disabledState.value = true;
            countryId.value = '';
            form.value.setValues({
                stateId: ''
            });
        }
        const loading = computed(() => moduleForm.loading);
        const loadingCombo = computed(() => moduleForm.loadings.countrySelect);
        const countries = computed(() => moduleForm.selects.optionsCountry);

        const loadingState = computed(() => moduleForm.loadings.stateSelect);
        const states = computed(() => moduleForm.selects.optionsState);

        watch(() => moduleForm.getValuesForm, (currentValue: NewPopulation) => {
            if(currentValue && currentValue.name != ''){
                form.value.setValues({
                    ...(currentValue as NewPopulation)
                });
            } else {
                form.value.resetForm();
            }

        })


        return {
            FormTypes
            , validationSchema
            , form
            , loading
            , countries
            , loadingCombo
            , states
            , loadingState
            , disabledState
            , save
            , searchCountry
            , countryChange
            , searchState
            , clearComboState
        }
    }
})
