export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "es": {
        "STATUS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estatus"])},
        "ALL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos"])}
      }
    }
  })
}
