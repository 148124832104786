import Modules from "@/store/enums/Modules";
import store from "@/store";
import { Action, getModule, Module, Mutation, MutationAction, VuexModule } from "vuex-module-decorators";
import { Identifiable } from "@/models/general/Identifiable";
import { realoadTable } from '@/store/functions/GenericTable';
import { MutationsForm } from "@/store/commons/MutationsForm"
import { ActionsForm } from "@/store/commons/ActionsForm";
import NewPopulationState from "../../models/NewPopulationState";
import NewPopulation from "@/models/population/NewPopulation";
import PopulationCatalogService from "@/core/services/population/PopulationCatalogService";
import OptionSelect from "@/models/shared/OptionSelect";
import CountryComboService from "@/core/services/country/CountryComboService";
import StateComboService from "@/core/services/state/StateComboService";
import { SaveValuesToStore } from "@/core/shared/AssingProps";


@Module({dynamic: true, store, namespaced: true, name: Modules.NewPopulationModule})
class NewPopulationModule extends VuexModule implements NewPopulationState {
    selects = {
        optionsCountry: [] as OptionSelect[]
        , optionsState:[] as OptionSelect[]
    };
    loadings = {
        countrySelect: false
        , stateSelect: false
    };
    idSelected = '';
    /**
     * Los valores iniciales son los valores del formulario, se usan para las ediciones
     */
    initialValues = formDefaultValues
    loading = false;
    

    get getValuesForm(): NewPopulation {
        return this.initialValues;
    }
    /**
     * Indica si existe un identificador de un cliente a modificar
     */
    get IsUpdateForm() : boolean {
        return this.idSelected != '';
    }
    
    @Mutation
    [MutationsForm.RESET_VALUES_FORM](){
        this.initialValues = formDefaultValues;
    }
    @Mutation
    [MutationsForm.RESET_ID_SELECT](){
        this.idSelected = '';
    }

    @Mutation
    [MutationsForm.SET_DATA_FORM](values: Identifiable<string, NewPopulation>){
        if(values && (values.id ?? '') != ''){
            this.initialValues = values as NewPopulation;
            this.idSelected = values.id;
        }
    }

    @Mutation
    [MutationsForm.SET_LOADINGS](loadings: any){
        if (typeof loadings == "boolean") {
            this.loading = loadings;
        } else { 
            SaveValuesToStore(this.loadings, loadings);
        }
    }

    @Action
    async [ActionsForm.SAVE_FORM](form: NewPopulation){
        this.context.commit(MutationsForm.SET_LOADINGS, true);
        return service.save(form)
            .then((resp) => {
                if(resp.data){
                    realoadTable(this.context, Modules.PopulationTableModule)
                    this.context.commit(MutationsForm.RESET_VALUES_FORM)
                } 
                return resp.data;
            })
            .finally(() => {
                this.context.commit(MutationsForm.SET_LOADINGS, false)
            })
    }
    @Action
    async [ActionsForm.UPDATE_FORM](newData: NewPopulation){
        this.context.commit(MutationsForm.SET_LOADINGS, true);
        return service.update(this.idSelected, newData)
            .then((resp) => {
                if(resp.data){
                    realoadTable(this.context, Modules.PopulationTableModule)
                    this.context.commit(MutationsForm.RESET_VALUES_FORM)
                } 
                return resp.data;
            })
            .finally( () => {
                this.context.commit(MutationsForm.SET_LOADINGS, false)
            });
    }
    @Action({ commit: MutationsForm.SET_DATA_FORM })
    async [ActionsForm.SEARCH_DATA_FORM](id: string){
        this.context.commit(MutationsForm.SET_LOADINGS, true);
        return (await service.getInfo(id)
            .finally( () => this.context.commit(MutationsForm.SET_LOADINGS, false))).data ?? formDefaultValues
    }
    @MutationAction
    async getComboCountry(filterName: string) {
        this.context.commit(MutationsForm.SET_LOADINGS, { countrySelect: true });
        const countries = await serviceCountry.searchCombo(filterName)
            .finally(() => { 
                this.context.commit(MutationsForm.SET_LOADINGS, { countrySelect: false });
            });
        const selects = { ...this.selects }
        return { selects: { ...selects, optionsCountry: countries.data ?? [] }}
    }
    @MutationAction
    async getComboState(payload: { filterName: string, countryId: string }) {
        this.context.commit(MutationsForm.SET_LOADINGS, { stateSelect: true });
        const states = await serviceState.searchCombo(payload.filterName, { countryId: payload.countryId })
            .finally(() => { 
                this.context.commit(MutationsForm.SET_LOADINGS, { stateSelect: false });
            });
        const selects = { ...this.selects }
        return { selects: { ...selects, optionsState: states.data ?? [] }}
    }
}
const service =  new PopulationCatalogService();
const serviceCountry =  new CountryComboService();
const serviceState =  new StateComboService();

/**
 * Valores por defecto del formulario
 */
const formDefaultValues = {
    name: ''
    , countryId: ''
    , stateId: ''
} as NewPopulation

export default getModule(NewPopulationModule);

